<template>
  <website>
    <change-password-card />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import ChangePasswordCard from '../components/Profile/ChangePasswordCard.vue'

  export default {
    name: 'ChangePassword',
    components: {
      Website,
      ChangePasswordCard,
    },
  }
</script>
