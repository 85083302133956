<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('components.website.changePassword.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="old"
          :label="$t('components.website.changePassword.oldPassword')"
          :rules="[ getRequiredRule() ]"
          :error-messages="getPropertyErrorMessages('old')"
          type="password"
          @keyup="onKeyUp"
        />
        <v-text-field
          v-model="password"
          :label="$t('components.website.changePassword.newPassword')"
          :rules="[ getRequiredRule(), getMinLengthRule(6) ]"
          :error-messages="getPropertyErrorMessages('password')"
          type="password"
          @keyup="onKeyUp"
        />
        <v-text-field
          v-model="password_confirmation"
          :label="$t('components.website.changePassword.confirmPassword')"
          :rules="[ getRequiredRule(), getMinLengthRule(6) ]"
          :error-messages="getPropertyErrorMessages('password_confirmation')"
          type="password"
          @keyup="onKeyUp"
        />
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn dark color="green" :loading="loading" @click="onSubmit">
        {{ $t('components.website.changePassword.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'ChangePasswordCard',
    components: {
      ValidationsAlert,
    },
    mixins: [
      FormValidations([
        'old',
        'password',
        'password_confirmation',
      ]),
      Themeable,
    ],
    data: vm => ({
      valid: false,
      loading: false,
    }),
    methods: {
      onKeyUp (e) {
        if (e.keyCode === 13) {
          this.onSubmit()
        }
      },

      onSubmit () {
        this.$refs.form.validate()

        if (this.valid) {
          this.loading = true
          this.$store.dispatch('profile/changePassword', {
            old: this.old,
            password: this.password,
            confirmation: this.password_confirmation,
          })
            .then(json => {
              this.updateFormSuccessMessage(json.message)
              this.old = null
              this.password = null
              this.password_confirmation = null
              this.$refs.form.resetValidation()
            })
            .catch(err => {
              this.updateFormValidationErrors(err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
    },
  }
</script>
